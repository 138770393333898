<template>
  <!-- 内部员工通过用户名获取验证码 -->
  <van-dialog v-model="show"
              show-cancel-button
              :beforeClose="beforeClose"
              :confirm-button-text="$t('module.confirm')"
              :cancel-button-text="$t('module.cancel')">
    <van-field v-model="code"
               center
               required
               clearable
               :label="$t('entSMS.smTxt1')"
               :placeholder="$t('entSMS.smTxt2')"
               :border="false"
               use-button-slot>
      <van-button slot="button"
                  size="small"
                  type="info"
                  @click="gainCode">{{time}}</van-button>
    </van-field>
  </van-dialog>
</template>

<script>
import { Notify } from 'vant'
import { getUserSmsCode } from '@api/wx.js'

export default {
  data () {
    return {
      show: false,
      isClick: true,
      time: this.$t('entSMS.smTxt3'),
      phone: '',
      key: '',
      code: '',
      s: 60
    }
  },
  watch: {
    show (n) {
      if (n)
      {
        this.key = ''
        this.code = ''
        this.time = this.$t('entSMS.smTxt3')
        this.isClick = true
        this.s = 60
      }
    }
  },
  methods: {
    // 获取验证码
    async gainCode () {
      if (this.isClick)
      {
        // 因为在登录界面也会使用，所以要改为实时获取缓存，不能放到data中获取
        const userInfo = localStorage.userInfo
          ? JSON.parse(localStorage.userInfo)
          : {}
        if (window.dev)
        {
          alert(localStorage.userInfo)
        }
        const res = await getUserSmsCode({ username: userInfo.username }) // 获取验证码接口
        // console.log(resA);
        // const res = resA[0] //接口传入的是数组-_||
        if (res.iserror == 0)
        {
          Notify({ type: 'success', message: this.$t('entSMS.smTxt4') })
          this.key = res.topvalue
          if (res.topvalue == 1234)
          {
            this.code = 1234
          }
        } else if (res.iserror == 1)
        {
          Notify({
            type: 'danger',
            message: this.$t('entSMS.smTxt5') + res.errormsg
          })
        }
        this.isClick = false
        this.s = 60
        this.time = this.s + 's'
        let interval = setInterval(() => {
          this.s--
          this.time = this.s + 's'
          if (this.s < 0)
          {
            this.time = this.$t('entSMS.smTxt6')
            this.isClick = true
            clearInterval(interval)
          }
        }, 1000)
      }
    },
    beforeClose (action, done) {
      if (action === 'confirm')
      {
        if (this.code === '')
        {
          Notify({
            type: 'danger',
            message: this.$t('entSMS.smTxt7')
          })
        } else if (this.code != this.key)
        {
          Notify({
            type: 'danger',
            message: this.$t('entSMS.smTxt8')
          })
        } else
        {
          this.$emit('beforeClose', action)
        }
      } else
      {
        this.$emit('beforeClose', action)
      }
      done(false)
    }
  }
}
</script>

<style scoped lang="less">
</style>