<template>
  <div class="login-container">
    <div class="login-bg">
      <div class="login-header">
        <h2>{{$t('login.loginTxt1')}}</h2>
      </div>
      <div class="login-logo">
        <img src="@assets/img/loginBanner.png" alt />
      </div>
      <div class="form-group">
        <div class="form-item" style="margin-top:0;">
          <input id="phone" type="text" :placeholder="$t('login.loginTxt2')" v-model="username" />
        </div>
        <div class="form-item">
          <input id="code" type="password" :placeholder="$t('login.loginTxt3')" v-model="pwd" />
        </div>
      </div>
      <div class="form-password">
        <div class="rem-password">
          <input type="checkbox" id="checkbox" v-model="checked" />
          <label for="checkbox">{{$t('login.loginTxt4')}}</label>
        </div>
        <div class="forget-password" @click="handleForget">{{$t('login.loginTxt5')}}</div>
      </div>
      <div class="button-group">
        <a class="login-btn" @click="handleLogin">{{$t('login.loginTxt6')}}</a>
      </div>
      <div class="button-group">
        <van-dropdown-menu direction="up">
          <van-dropdown-item v-model="language" :options="langs" @change="handleCommand" />
        </van-dropdown-menu>
      </div>
    </div>
    <ddBind ref="ddBind" @beforeClose="goto" />
  </div>
</template>
<script>
import { checkUserLogin } from '@api/login.js'
import ddBind from '@components/ddBind'
import { Toast, DropdownMenu, DropdownItem } from 'vant'
import { getOpenId } from '@/utils/auth'
import { getLangList } from '@api/wx'
// import Cookies from 'js-cookie'
export default {
  components: {
    ddBind
  },
  name: 'login',
  data() {
    let v8_login_checked = localStorage.getItem('v8_login_checked')
    let v8_login_username = localStorage.getItem('v8_login_username')
    let v8_login_pwd = localStorage.getItem('v8_login_pwd')
    let checkedStatus = false
    let checkedUsername = ''
    let checkedPass = ''
    if (v8_login_checked == 1) {
      checkedStatus = true
    } else {
      checkedStatus = false
    }
    if (v8_login_username) {
      checkedUsername = v8_login_username
    } else {
      checkedUsername = ''
    }
    if (v8_login_pwd) {
      checkedPass = v8_login_pwd
    } else {
      checkedPass = ''
    }
    return {
      username: checkedUsername,
      pwd: checkedPass,
      checked: checkedStatus,
      language: 0,
      langs: [],
      lang: [],
      langid: 0
    }
  },
  created() {
    // window.login = this
    this.getLangue()
    if (localStorage.getItem('langid')) {
      localStorage.setItem('langid', localStorage.getItem('langid'))
    } else {
      localStorage.setItem('langid', this.langid)
      // Cookies.set('langid', this.langid)
    }
  },
  mounted() {
    if (
      localStorage.getItem('v8_login_login') === '1' &&
      sessionStorage.out != '1'
    ) {
      this.handleLogin()
    }
  },
  methods: {
    handleLogin() {
      if (!this.username) {
        Toast(this.$t('login.loginTxt7'))
        return false
      }
      if (!this.pwd) {
        Toast(this.$t('login.loginTxt8'))
        return false
      }
      let hasOpenId = getOpenId()
      if (hasOpenId === '0') hasOpenId = ''
      checkUserLogin({
        username: this.username.trim(),
        pwd: this.pwd,
        langid: this.langid || 0,
        openid: hasOpenId || ''
      }).then(res => {
        if (res.iserror == '0') {
          if (res.data[0].userid == -1) {
            Toast(res.data[0].errormsg)
          } else {
            localStorage.setItem('v8_login_pwd', this.pwd)
            if (this.checked) {
              localStorage.setItem('v8_login_checked', 1)
              localStorage.setItem('v8_login_username', this.username.trim())
              // localStorage.setItem('v8_login_pwd', this.pwd)
            } else {
              localStorage.setItem('v8_login_checked', 0)
              localStorage.setItem('v8_login_username', '')
              // localStorage.setItem('v8_login_pwd', '')
            }
            localStorage.setItem('userid', res.data[0].userid)
            localStorage.setItem('userInfo', JSON.stringify(res.data[0]))
            sessionStorage.setItem('out', '2')
            Toast(this.$t('login.loginTxt9'))
            if (window.DingDing) {
              // 启用钉钉，开始鉴权
              // this.DingDingSign()
              this.$refs.ddBind.checkDdUserid()
              // this.$refs.ddBind.DingDingSign()
            } else {
              this.goto()
            }
          }
        }
      })
    },
    // 获取语言列表
    getLangue() {
      getLangList().then(res => {
        if (res.iserror === '0') {
          const data = res.data
          const langs = []
          const lang = []
          data.forEach(item => {
            langs.push({ text: item.langname, value: item.langid }) // ['简体中文', '繁体中文', 'English', 'Japan', 'french']
            lang.push(item.shortname) // ['zh', 'zh-TW', 'en', 'ja', 'fr']
          })
          this.langs = langs
          this.lang = lang
          if (localStorage.getItem('langid')) {
            this.language = this.langs[localStorage.getItem('langid')].value
            this.langid = localStorage.getItem('langid')
          }
        }
      })
    },
    handleCommand(command) {
      console.log(command, 'handleCommand()')
      // const langs = ['简体中文', '繁体中文', 'English', 'Japan', 'french']
      // const lang2 = ['zh', 'zh-TW', 'en', 'ja', 'fr']
      this.langid = command
      this.$i18n.locale = 'lang' + command
      localStorage.setItem('langid', this.langid)
      localStorage.setItem('language', this.langid)
      document.title = `${this.$t('module.title')}`
      // localStorage.setItem('langid', this.langid)
      // Cookies.set('language', this.lang[command])
    },
    // 跳转
    goto() {
      let fullpath = localStorage.getItem('V8_REDIRECT_PATH')
      if (fullpath) {
        localStorage.setItem('V8_REDIRECT_PATH', '')
        this.$router.push(fullpath)
      } else {
        this.$router.push('/home?v=' + new Date().getTime())
      }
    },
    //忘记密码界面
    handleForget() {
      this.$router.push('/ForgetPwd')
    }
  }
}
</script>
<style lang="less" scoped>
.login-container {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background: #fff;
  * {
    margin: 0;
    padding: 0;
  }
}

.login-bg {
  width: 100%;
  height: 100%;
  background: #fff;
  padding: 0 30px;
  .login-header {
    padding-top: 70px;
    h2 {
      font-size: 50px;
      color: #000;
      font-weight: bold;
      font-family: PingFang SC;
    }
  }
  .login-logo {
    width: 100%;
    img {
      width: 100%;
    }
  }
  .form-group {
    width: 100%;
    .form-item {
      border-bottom: 1px #ccc solid;
      margin-top: 30px;
      height: 1.2rem;
      display: flex;
      align-items: center;
      label img {
        width: 25px;
        position: absolute;
        margin-top: 2%;
      }
      input {
        outline: none;
        border: 0;
        background-color: transparent;
        color: #333;
        font-size: 36px;
        width: 100%;
      }
    }
  }
  .button-group {
    width: 90%;
    height: 86px;
    margin: 0 auto;
    margin-top: 116px;
  }
  .login-btn {
    width: 100%;
    height: 86px;
    background: #0b82f8;
    opacity: 0.8;
    border-radius: 44px;
    display: block;
    line-height: 86px;
    color: #fff;
    text-align: center;
    font-weight: bold;
    font-size: 32px;
  }
  .form-password {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    margin-top: 15px;
    .rem-password {
      height: 60px;
      display: flex;
      align-items: center;
      line-height: 60px;
      input {
        width: 30px;
        height: 30px;
        display: block;
      }
      label {
        font-size: 30px;
        margin-left: 0.15rem;
        line-height: 60px;
        height: 60px;
        display: block;
        color: #999999;
      }
    }
    .forget-password {
      font-size: 30px;
      line-height: 60px;
      height: 60px;
      display: block;
      color: #999999;
    }
  }
}
</style>